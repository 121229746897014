import React, {createContext, ReactNode, useEffect, useMemo, useState} from 'react';
import CrowdSaleContract from '../contracts/CrowdSale.json';
import {PresaleStepConfig} from '../constants/ChainIDs';
import { useWeb3ModalNetwork } from '@web3modal/react';
import {useAccount, useContractReads} from 'wagmi';
import convertBigNumbersInArray from '../helpers/convertBigNumbers';

interface VestingCriteriaType {
  untilDate: string;
  percentage: string;
}

interface ContractContextData {
  address?: string;
  ABI: object[];
  instance: object;
  rate?: string;
  startDate?: string;
  endDate?: string;
  isWhiteListed?: boolean;
  canClaim?: boolean;
  targetWei?: string;
  raisedWei?: string;
  balanceOf?: string;
  claimableTokens?: string;
  vestingCriteria?: VestingCriteriaType[];
  vestingEnabled?: boolean;
}

interface ContractProviderData {
  contract: ContractContextData;
  setIsSending: (isSending: boolean) => void;
  isSending: boolean;
}
const CONTRACT_DATA: any = {
  rate: null,
  startDate: null,
  endDate: null,
  isWhiteListed: null,
  canClaim: null,
  targetWei: null,
  raisedWei: null,
  vestingCriteria: null,
  vestingEnabled: null,
};

const BALANCE_DATA: any = {
  claimableTokens: null,
  balanceOf: null,
};

const defaultValue = {
  contract: {
    address: '',
    ABI: CrowdSaleContract.abi,
    instance: {
      address: '',
      abi: CrowdSaleContract.abi,
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsSending: (isSending: boolean) => {
  },
  isSending: false,
};
const ContractContext = createContext<ContractProviderData>(defaultValue);


export default ContractContext;



export const ContractProvider = ({children, contractsConfig}: { children: ReactNode, contractsConfig: PresaleStepConfig }) => {
  const { isConnected, address } = useAccount();
  const { selectedChain } = useWeb3ModalNetwork();
  const [validWallet, setValidWallet] = useState<boolean>(false);
  const [contract, setContract] = useState<ContractContextData>(defaultValue.contract);
  const [isSending, setIsSending] = useState<boolean>(false);
  const contractCalls = useMemo(()=>{

    return Object.keys(CONTRACT_DATA).map(field => ({
      ...contract.instance,
      functionName: field,
    }));
  }, [contract]);

  const balanceCalls = useMemo(()=>{
    return Object.keys(BALANCE_DATA).map(field => ({
      ...contract.instance,
      functionName: field,
      args: [address]
    }));
  }, [contract, address]);


  const { data, isError, isLoading, refetch, error } = useContractReads({
    contracts: contractCalls
  });

  const claimable = useContractReads({
    contracts: balanceCalls
  });


  useEffect(() => {
    const isValid = !!selectedChain && isConnected && Object.keys(contractsConfig).includes(`${selectedChain.id}`);
    setValidWallet(isValid);
    if (isValid){
      setContract(prevContract=>{
        return {
          ...prevContract,
          address: contractsConfig[`${selectedChain.id}`].contractAddress,
          instance: {
            abi: prevContract.ABI,
            address: contractsConfig[`${selectedChain.id}`].contractAddress
          }
        };
      });
      refetch();
    }else {
      setContract(prevContract=>{
        return {
          ...prevContract,
          address: undefined,
          instance: {
            abi: prevContract.ABI,
            address: undefined
          }
        };
      });
    }
  }, [selectedChain, isConnected, contractsConfig]);


  useEffect(()=>{
    console.log({data});
    if (!data || data.length !== Object.keys(CONTRACT_DATA).length) {
      return;
    }
    const parsedData = convertBigNumbersInArray(data);
    Object.keys(CONTRACT_DATA).forEach((dataIndex, index)=>{
      if (dataIndex) {
        CONTRACT_DATA[dataIndex] = parsedData[index];
      }
    });
    console.log({CONTRACT_DATA});
    setContract(prevState => ({...prevState, ...CONTRACT_DATA}));

  }, [data]);


  useEffect(()=>{
    if (isSending || !isConnected)
      return;
    refetch();
    claimable.refetch().then((data: any) =>{
      if (data.status === 'error')
        return;
      if (!data.data || data.data.length !== Object.keys(BALANCE_DATA).length) {
        return;
      }
      const parsedData = convertBigNumbersInArray(data.data);
      Object.keys(BALANCE_DATA).forEach((dataIndex, index)=>{
        if (dataIndex) {
          BALANCE_DATA[dataIndex] = parsedData[index];
        }
      });
      setContract(prevState => ({...prevState, ...BALANCE_DATA}));
    }).catch(err => {
      // ignore
      console.log(err);
    });
  }, [isSending, isConnected, contract.instance]);

  return (
    <ContractContext.Provider
      value={{contract, setIsSending, isSending}}
    >
      {children}
    </ContractContext.Provider>
  );
};
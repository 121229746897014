import {styled} from '@mui/material/styles';

const StyledInput = styled('input')`
 border-radius: 6px;
  border: none;
  font-size: 15px;
  width: 100%;
  padding: 10px 7px;
  outline: 0;
  color: #3c1c94;
  font-weight: normal;
  background: #e7dfff;
  &:focus {
    border: none;
    outline:0;
  }
`;

const StyledSelectorItem = styled('div')`
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  border-radius: 6px;
  padding: 10px 30px;
`;

const StyledSelectedSelectorItem = styled(StyledSelectorItem)`
    background: #fff;
    color: #47209c;
`;

const StyledCalculatedAmountText = styled('div')`
  color: #ffffff;
`;

const StyledRateText = styled('div')`
  display: flex;
  text-align: end;
  flex-direction: column;
`;

export {StyledInput, StyledSelectorItem, StyledSelectedSelectorItem, StyledCalculatedAmountText, StyledRateText};
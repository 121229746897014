import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import {DRAWER_WIDTH} from '../../organisms/app-drawer/AppDrawer';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  background: '#47209c',
  padding: '13px',
  borderRadius: '6px',
  [theme.breakpoints.down('md')]: {
    width: '80vw',
  },
  [theme.breakpoints.up('md')]: {
    width: '40vw',
    marginLeft: `${DRAWER_WIDTH}px`,
  }
}));


export {
  StyledCard
};
import React, {FC} from 'react';
import {useContract} from '../../../hooks/useContract';
import {TableContainer, Table, TableRow, Paper, TableHead, TableBody} from '@mui/material';
import {StyledTableCell} from './VestingTableStyles';
import Heading from '../../atoms/heading/Heading';
import Spacer from '../../atoms/spacer/Spacer';
const VestingTable: FC = ()=>{
  const {contract} = useContract();

  return <>
    {contract.vestingEnabled &&
        <>
          <Heading level={4}>Vesting Table</Heading>
          <Spacer space='5px'/>

          <TableContainer component={Paper}>
            <Table sx={{ width: '100%' }} aria-label="vesting table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Time & Date</StyledTableCell>
                  <StyledTableCell align="right">Percentage</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contract.vestingCriteria?.map(({untilDate, percentage}, index) => (
                  <TableRow key={`row_${index}`}>
                    <StyledTableCell component="th" scope="row">
                      {new Date(parseInt(untilDate) * 1000).toUTCString()}
                    </StyledTableCell>
                    <StyledTableCell align="right">{parseInt(percentage) / 100} %</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>

    }
  </>;
};

export default VestingTable;
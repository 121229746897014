import React from 'react';
import {CrowdSale} from '../components/organisms/crowd-sale/CrowdSale';
import Claim from '../components/organisms/claim/Claim';
import { Navigate } from 'react-router-dom';
import ContractConfigs from './ContractConfigs';

interface PageConfig {
	title: string;
	url: string;
	element: React.ReactNode;
}
const parsePages = ()=> {
  return ContractConfigs.map((contractConfig, index)=>{
    return [
      {
        title: contractConfig.saleTitle.drawer,
        url: `/presale-${(index+1)}`,
        element: <CrowdSale title={contractConfig.saleTitle.page}  contract={contractConfig.contracts}/>,
      },
      {
        title: contractConfig.claimTitle.drawer,
        url: `/claim-${(index+1)}`,
        element: <Claim title={contractConfig.claimTitle.page} contract={contractConfig.contracts}/>,
      },
    ];
  }).flat();
};
const PagesConfig: PageConfig[] = [
  {
    title: 'Dapp',
    url: '/',
    element: <Navigate to='/presale-1'/>,
  },
  ...parsePages(),
];

export default PagesConfig;
import React, {Box, InputLabel, LinearProgress} from '@mui/material';
import {useContract} from '../../../hooks/useContract';
import {useEffect, useState, useMemo} from 'react';
import {StyledLinearProgress} from './TargetIndicatorStyles';
import {utils} from 'ethers';

const TargetIndicator = () => {
  const {contract, isSending} = useContract();
  const [target, setTarget] = useState<number>(0);
  const [raised, setRaised] = useState<number>(0);

  useEffect(() => {
    if (contract.targetWei) {
      try{
        setTarget(parseInt(utils.formatEther(contract.targetWei)));
      }catch (e) {
        console.warn(e);

      }
    }
    if (contract.raisedWei) {
      try{
        setRaised(parseFloat(parseFloat(utils.formatEther(contract.raisedWei)).toFixed(3)));
      }catch (e) {
        console.warn(e);
      }
    }
  }, [contract.targetWei, contract.raisedWei]);

  const progressValue = useMemo(() => {
    if (target === 0 ) return 0;
    return raised * 100 / target;
  }, [raised, target]);

  const progressVariant = useMemo(()=>(target === 0 || isSending) ? 'indeterminate': 'determinate', [target, isSending]);

  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'start',
    marginTop: '10px'
  }}>
    <InputLabel shrink style={{color: '#ffffff99'}}>{raised} BNB / {target} BNB</InputLabel>
    <StyledLinearProgress variant={progressVariant} value={progressValue}/>

  </Box>;
};

export default TargetIndicator;
import React, {useCallback} from 'react';
import {StyledButton, StyledDiv} from './WalletConnectStyles';
import {Box} from '@mui/material';
import shortifyAddress from '../../../helpers/shortifyAddress';
import {useAccount} from 'wagmi';
import {useWeb3Modal} from '@web3modal/react';

const WalletConnect = () => {
  const { isConnected, address } = useAccount();
  const { open } = useWeb3Modal();

  const handleClick = useCallback(() => {
    open();
  }, []);


  return (
    <Box sx={{position: 'absolute', top: '0', right: '0'}}>
      {!isConnected && <StyledButton variant="contained" onClick={handleClick}>Connect</StyledButton>}
      {isConnected && <StyledDiv>{shortifyAddress(address || '')}</StyledDiv>}
    </Box>);
};

export default WalletConnect;
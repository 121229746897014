import React from 'react';
import WalletConnect from '../organisms/wallet-connect/WalletConnect';
import AppDrawer from '../organisms/app-drawer/AppDrawer';
import MainPage from '../pages/main-page/MainPage';



export default function Root() {

  return (

    <>
      <WalletConnect/>
      <AppDrawer/>
      <div>
        <MainPage/>
      </div>
    </>
  );
}
import { BigNumber } from 'ethers';

function convertBigNumbersInArray(arr: any[]): any[] {
  const convertedArray: any[] = [];
  for (const item of arr) {
    if (item === null) {
      convertedArray.push(null);
    }else if (item instanceof BigNumber) {
      convertedArray.push(item.toString());
    } else if (Array.isArray(item)) {
      convertedArray.push(convertBigNumbersInArray(item));
    } else if (typeof item === 'object') {
      convertedArray.push(convertBigNumbersInObject(item));
    } else {
      convertedArray.push(item);
    }
  }
  return convertedArray;
}

function convertBigNumbersInObject(obj: any): any {
  if (typeof obj !== 'object') {
    return obj;
  }
  const convertedObj: any = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (obj[key] instanceof BigNumber) {
        convertedObj[key] = obj[key].toString();
      } else {
        convertedObj[key] = convertBigNumbersInObject(obj[key]);
      }
    }
  }
  return convertedObj;
}

export default convertBigNumbersInArray;




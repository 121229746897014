import {styled} from '@mui/material/styles';
import {
  IconButton,
  Toolbar
} from '@mui/material';

const MenuButton = styled(IconButton)`
	position: absolute;
	margin: 13px;
`;

const StyledDrawerContainer = styled('div')`
	color: #ffffff;
	background: #3c1c94;
	height: 100vh;
`;

const StyledLogo = styled('p')`
	font-weight: bolder;
	font-size: 26px;
	margin: 0;
    margin-top: 5px;
`;

const StyledToolbar = styled(Toolbar)`
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
`;
export {MenuButton, StyledDrawerContainer, StyledLogo, StyledToolbar};
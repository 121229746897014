import {Box, InputLabel, Button} from '@mui/material';
import {useContract} from '../../../hooks/useContract';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {StyledInput, StyledSelectorItem, StyledSelectedSelectorItem, StyledRateText, StyledCalculatedAmountText} from './BuyCalculatorStyles';
import thousandSeparator from '../../../helpers/thousandSeparator';
import { usePrepareContractWrite , useContractWrite, useWaitForTransaction} from 'wagmi';
import {utils} from 'ethers';
import useDebounce from '../../../hooks/useDebounce';

const BuyCalculator = () => {
  const {contract, isSending, setIsSending} = useContract();
  const [byToken, setByToken] = useState<boolean>(false);
  const [bnb, setBnb] = useState<string>('0');
  const [token, setToken] = useState<string>('');
  const [bnbSpent, setBnbSpent] = useState<string>('0');
  const [receivedToken, setReceivedToken] = useState<string>('0');
  const bnbBuyValue = useMemo(()=> 
    byToken ? bnbSpent : parseFloat(bnb || '0').toFixed(3)
  , [byToken, bnbSpent, bnb]);
  const [rate, setRate] = useState<number>(0);
  const isBuyActive = useMemo(()=>(contract.endDate! && !isSending && !contract.canClaim), [contract.instance,contract.canClaim, isSending]);
  const debouncedBnbBuyValue = useDebounce(bnbBuyValue, 500);

  const { config } = usePrepareContractWrite({
    ...contract.instance,
    functionName: 'buyTokens',
    overrides: {
      value:  utils.parseEther(debouncedBnbBuyValue)
    },
    enabled: !!isBuyActive && parseFloat(debouncedBnbBuyValue) > 0,
  });

  // @ts-ignore
  const { data, write } = useContractWrite(config);

  const { isLoading, isSuccess, isError } = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(()=>{
    setIsSending(isLoading);
  }, [isLoading, isSuccess]);


  useEffect(() => {
    if (contract.rate) {
      setRate(parseInt(contract.rate));
    }
  }, [contract.rate]);

  const handleBNBChange = (evt: any)=>{
    const bnbAmount = parseFloat(evt.target.value);

    setBnb(evt.target.value);
    if (rate){
      setReceivedToken(thousandSeparator(rate * bnbAmount));
    }
  };
  const handleTokenChange = (evt:any)=>{
    const tokenAmount = parseInt(evt.target.value || 0);
    setToken(evt.target.value);
    if (rate){
      setBnbSpent((tokenAmount/rate).toFixed(3));
    }
  };

  const handleClick = ()=>{
    if (!isBuyActive)
      return;
    if (!write)
      return;

    write?.();
  };

  const handleByTokenClick = useCallback(()=>{
    setByToken(true);
  }, [byToken, setByToken]);

  const handleByBnbClick = useCallback(()=>{
    setByToken(false);
  }, [byToken, setByToken]);

  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    marginTop: '25px'
  }}>
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-around',
      alignItems: 'center',
    }}>
      {!byToken &&
      <>
        <StyledSelectedSelectorItem>By BNB</StyledSelectedSelectorItem>
        <StyledSelectorItem onClick={handleByTokenClick}>By QAIR</StyledSelectorItem>
      </>}
      {byToken &&
      <>
        <StyledSelectorItem onClick={handleByBnbClick}>By BNB</StyledSelectorItem>
        <StyledSelectedSelectorItem>By QAIR</StyledSelectedSelectorItem>
      </>}
    </Box>
    {!byToken &&
        <>
          <InputLabel shrink style={{marginTop: '25px', color: '#ffffff99'}}>BNB</InputLabel>
          <StyledInput value={bnb} onChange={handleBNBChange}/>

          <InputLabel shrink style={{marginTop: '25px', color: '#ffffff99'}}>Received QAIR Amount</InputLabel>
          <StyledCalculatedAmountText>{`${receivedToken} QAIR`}</StyledCalculatedAmountText>
        </>}
    {byToken &&
        <>
          <InputLabel shrink style={{ marginTop: '25px', color: '#ffffff99'}}>QAIR</InputLabel>
          <StyledInput value={token} onChange={handleTokenChange}/>

          <InputLabel shrink style={{marginTop: '25px', color: '#ffffff99'}}>BNB Value</InputLabel>
          <StyledCalculatedAmountText>{`${bnbSpent} BNB`}</StyledCalculatedAmountText>
        </>}

    <StyledRateText style={{marginTop: '15px'}}>
      <InputLabel style={{ color: '#ffffff99', fontSize: '0.75rem'}}>{`${contract.rate} QAIR/BNB`}</InputLabel>
      <a href='https://www.tradingview.com/symbols/BNBUSDT/' rel="noreferrer" target='_blank' style={{ color: '#ffffff99', fontSize: '0.75rem'}}>BNB Price</a>
    </StyledRateText>

    {contract.canClaim &&
        <InputLabel shrink style={{color: '#ff7575'}}>Sale has ended</InputLabel>
    }

    <Button
      variant="contained"
      style={{width: '100%', marginTop: contract.canClaim? '10px': '40px', borderRadius: '6px', opacity: isBuyActive && write? 1: '0.5'}}
      onClick={handleClick}>Buy</Button>
  </Box>;
};

export default BuyCalculator;
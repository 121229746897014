import React, {FC, useMemo} from 'react';
import Heading from '../../atoms/heading/Heading';
import ClaimCalculator from '../../molecules/claim-calculator/ClaimCalculator';
import VestingTable from '../../molecules/vesting-table/VestingTable';
import {Box} from '@mui/material';
import Spacer from '../../atoms/spacer/Spacer';
import {PresaleStepConfig} from '../../../constants/ChainIDs';
import AbstractContractPage from '../../pages/abstract-contract-page/AbstractContractPage';
import {useContract} from '../../../hooks/useContract';

interface ClaimProps {
  title: string;
  contract: PresaleStepConfig;
}
const ClaimInside: FC<ClaimProps> = ({title})=> {
  const {contract} = useContract();
  const isBuyActive = useMemo(()=>(contract.endDate!), [contract.endDate]);
  
  
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'start',
      alignItems: 'center',
      padding: '3px 7px',
      filter: isBuyActive? 'none': 'blur(5px)'
    }}>
      <Heading level={2}>{title}</Heading>

      <ClaimCalculator/>
      <Spacer space='17px'/>
      <VestingTable/>
    </Box>
  );
};
const Claim: FC<ClaimProps> = (
  {title, contract:contractConfig}
) => {
  return (
    <AbstractContractPage contractsConfig={contractConfig}>
      <ClaimInside title={title} contract={contractConfig}/>
    </AbstractContractPage>
  );
};

export default Claim;
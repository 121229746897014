import {styled} from '@mui/material/styles';
import {Button} from '@mui/material';

const StyledButton = styled(Button)(({ theme }) => ({
  margin: '13px',
  borderRadius: '6px',
}));

const StyledDiv = styled('div')(({ theme }) => ({
  margin: '17px',
  color: '#ffffff',
  fontWeight: 'bolder'
}));

export {
  StyledButton,
  StyledDiv
};
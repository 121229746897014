import React, {FC, useMemo, useState, useCallback, useEffect} from 'react';
import {useContract} from '../../../hooks/useContract';
import {Box, Button, InputLabel} from '@mui/material';
import thousandSeparator from '../../../helpers/thousandSeparator';
import Spacer from '../../atoms/spacer/Spacer';
import { utils } from 'ethers';
import {usePrepareContractWrite, useAccount, useContractWrite, useWaitForTransaction} from 'wagmi';

const ClaimCalculator: FC = ()=>{

  const {address} = useAccount();
  const {contract, setIsSending, isSending} = useContract();
  const [availableTokens, setAvailableTokens] = useState<string>('~');
  const [balance, setBalance] = useState<string>('~');
  const isButtonActive = useMemo(()=>(contract.endDate! && !isSending && contract.canClaim), [contract.instance, isSending, contract.canClaim]);

  const { config } = usePrepareContractWrite({
    ...contract.instance,
    functionName: 'claimTokens',
    args: [address],
    enabled: !!isButtonActive && parseFloat(availableTokens) > 0,
  });

  // @ts-ignore
  const { data, write } = useContractWrite(config);

  const { isLoading, isSuccess, isError } = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(()=>{
    setIsSending(isLoading);
  }, [isLoading, isSuccess]);

  const handleClick = ()=>{
    if (!isButtonActive)
      return;
    if (!write)
      return;
    
    if (!(parseFloat(availableTokens) > 0))
      return;

    write?.();
  };

  useEffect(()=>{
    if (!contract.balanceOf)
      return;

    try {
      setBalance(thousandSeparator(utils.formatEther(contract.balanceOf || '0')));
    }catch (e) {
      console.log(e);
    }
  }, [contract.balanceOf]);

  useEffect(()=>{
    if (!contract.claimableTokens)
      return;
    try {
      setAvailableTokens(thousandSeparator(utils.formatEther(contract.claimableTokens)));
    }catch (e) {
      console.log(e);
    }
  }, [contract.claimableTokens]);

  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    marginTop: '25px'
  }}>
    <InputLabel style={{ color: '#ffffff99', fontSize: '0.75rem'}}>{`Current Unclaimed Tokens: ${balance} QAIR`}</InputLabel>

    <Spacer space='25px' />
    {!contract.canClaim &&
        <InputLabel shrink style={{color: '#ff7575'}}>Claiming will be enabled after
            TGE.</InputLabel>
    }
    {contract.canClaim &&
        <InputLabel shrink style={{color: '#83ff75'}}>{`Available Tokens to claim: ${availableTokens} QAIR`}</InputLabel>
    }
    <Button
      variant="contained"
      style={{width: '100%', marginTop: '10px', borderRadius: '6px', opacity: isButtonActive  && parseFloat(availableTokens) > 0? 1: '0.5'}}
      onClick={handleClick}>{`Claim ${isButtonActive && parseFloat(availableTokens) > 0 ? `${availableTokens} QAIR`: '' }`}</Button>
  </Box>;
};

export default ClaimCalculator;
const thousandSeparator = (input: string|number): string =>{
  if (isNaN(<number>input))
    return '0';

  const numberValue = `${input}`.split(',').join();
  if (numberValue !== '')
    return parseInt(`${input}`.split(',').join()).toLocaleString();

  return '0';
};

export default thousandSeparator;
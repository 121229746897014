import {PresaleStepConfig} from './ChainIDs';

interface ContractConfigTypes {
  saleTitle: {
    drawer: string;
    page: string;
  };
  claimTitle: {
    drawer: string;
    page: string;
  };
  contracts: PresaleStepConfig;
}

const ContractConfigs: ContractConfigTypes[] = [
  {
    saleTitle: {
      drawer: 'Step 1 - Pre Sale',
      page: 'Private Sale - Step 1'
    },
    claimTitle: {
      drawer: 'Step 1 - Claim',
      page: 'Private Sale - Step 1 - Claim'
    },
    contracts: {
      '56' : {
        name: 'bscMain',
        contractAddress: '0x722e17CAB7A2Af83A99A802d789Ca95F35B9406C',
      },
      '97' : {
        name: 'bscTest',
        contractAddress: '0x96E08108f7eD2616333e31Fe6582e7ee6fc9Cdbb',
      },
    }
  },
];

export default ContractConfigs;

import React, {FC} from 'react';
import {
  Box,
  CssBaseline,
  Divider,
  Drawer,
  List,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import {MenuButton, StyledDrawerContainer, StyledLogo, StyledToolbar} from './AppDrawerStyles';
import DrawerItem from '../../atoms/drawer-item/DrawerItem';
import PagesConfig from '../../../constants/PagesConfig';
import {DrawerDivider} from '../../atoms/drawer-item/DrawerItemStyles';

const DRAWER_WIDTH = 240;

const DrawerLayout = () => {
  const handleLogoClick = ()=>{
    window.open('https://qaircoin.io', '_blank');
  };
  return (
    <StyledDrawerContainer>
      <StyledToolbar onClick={handleLogoClick}>
        <img style={{height: '40px'}} src="/icon-128.png"/>
        <StyledLogo>
        QAIR COIN
        </StyledLogo>
      </StyledToolbar>
      <Divider />
      <List>
        {PagesConfig.slice(1).map(({title, url}, index) => (
          <>
            <DrawerItem key={index} title={title} url={url} />
            {(index % 2 == 1 && index !== PagesConfig.length-2) && (<DrawerDivider />)}
          </>
        ))}
      </List>
    </StyledDrawerContainer>
  );};

const AppDrawer: FC = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window.document.body;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <MenuButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ display: { md: 'none' } }}
      >
        <MenuIcon />
      </MenuButton>
      <Box
        component="nav"
        sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: {xs:'block', sm: 'block', md: 'none', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH, backgroundColor: 'transparent'}
          }}
        >
          <DrawerLayout/>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs:'none', sm: 'none', md: 'block', lg: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH, backgroundColor: 'transparent' },
          }}
          open
        >
          <DrawerLayout />
        </Drawer>
      </Box>
    </Box>
  );
};

export default AppDrawer;
export {DRAWER_WIDTH};

import React, {ReactNode} from 'react';

interface HeadingProps {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  color?: string;
  children: ReactNode;
}

const headingStyles = {
  marginTop: '0.5rem',
  marginBottom: '0.5rem',
  color: '#e0e0e0'
};

const Heading = ({level, children}: HeadingProps) => {

  switch (level) {
  case 1:
    return <h1 style={headingStyles}>{children}</h1>;
  case 2:
    return <h2 style={headingStyles}>{children}</h2>;
  case 3:
    return <h3 style={headingStyles}>{children}</h3>;
  case 4:
    return <h4 style={headingStyles}>{children}</h4>;
  case 5:
    return <h5 style={headingStyles}>{children}</h5>;
  case 6:
    return <h6 style={headingStyles}>{children}</h6>;
  default:
    return null;
  }
};

export default Heading;
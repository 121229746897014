import {ContractProvider} from '../../../contexts/ContractContext';
import React, {FC, ReactNode} from 'react';
import {PresaleStepConfig} from '../../../constants/ChainIDs';

interface AbstractContractPageProps{
  children: ReactNode;
  contractsConfig: PresaleStepConfig
}
const AbstractContractPage: FC<AbstractContractPageProps> = ({contractsConfig, children})=>{
  return (
    <ContractProvider contractsConfig={contractsConfig}>
      {children}
    </ContractProvider>
  );
};

export default AbstractContractPage;
import React, {FC, useEffect, useMemo} from 'react';
import TargetIndicator from '../../molecules/target-indicator/TargetIndicator';
import {Box} from '@mui/material';
import Heading from '../../atoms/heading/Heading';
import BuyCalculator from '../../molecules/buy-calculator/BuyCalculator';
import {ContractProvider} from '../../../contexts/ContractContext';
import {PresaleStepConfig} from '../../../constants/ChainIDs';
import AbstractContractPage from '../../pages/abstract-contract-page/AbstractContractPage';
import {useContract} from '../../../hooks/useContract';

interface CrowdSaleProps {
  title: string;
  contract: PresaleStepConfig;
}
const CrowdSaleInside: FC<CrowdSaleProps> = (
  {title}
) => {
  const {contract} = useContract();
  const isBuyActive = useMemo(()=>(contract.endDate!), [contract.endDate]);
  
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'start',
      alignItems: 'center',
      padding: '3px 7px',
      filter: isBuyActive? 'none': 'blur(5px)'
    }}>
      <Heading level={2}>{title}</Heading>
      <TargetIndicator />

      <BuyCalculator />
    </Box>
  );
};

const CrowdSale: FC<CrowdSaleProps> = (
  {title, contract:contractConfig}
) => {
  return (
    <AbstractContractPage contractsConfig={contractConfig}>
      <CrowdSaleInside title={title} contract={contractConfig}/>
    </AbstractContractPage>
  );
};

export { CrowdSale };
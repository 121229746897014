import React, {FC, useMemo} from 'react';
import {DrawerItemContainer} from './DrawerItemStyles';
import { useLocation, useNavigate } from 'react-router-dom';

interface DrawerItemProps {
  title: string;
  url: string;
}

const DrawerItem: FC<DrawerItemProps> = ({title, url})=>{
  const currentLocation = useLocation();

  const isActive = useMemo(()=>{
    return currentLocation.pathname === url;
  }, [currentLocation.pathname]);

  const navigate = useNavigate();

  return (<DrawerItemContainer sx={{ backgroundColor: isActive? '#ffffff47': 'unset', color: isActive? '#fff': '#fff9'}} onClick={()=>{
    navigate(url);
  }}>
    <span >{title}</span>
  </DrawerItemContainer>);
};

export default DrawerItem;
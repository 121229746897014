import {styled} from '@mui/material/styles';

const DrawerItemContainer = styled('div')(() => ({
  margin: '5px 8px',
  padding: '8px 16px',
  borderRadius: '6px',
  cursor: 'pointer',
  fontWeight: '400'
}));

const DrawerDivider = styled('div')( ()=> ({
  height: '1px',
  width: '80%',
  margin: '10px 22px',
  background: '#848484'
}));

export {DrawerItemContainer, DrawerDivider};
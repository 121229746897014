import React, {FC, useMemo} from 'react';
import { Box } from '@mui/material';
import {StyledCard} from './MainPageStyles';
import { Outlet } from 'react-router-dom';

const MainPage: FC = ()=>{
  return <Box sx={{ display: 'flex', background: 'linear-gradient(45deg, #aafff7 0%, #6e19c2 100%)', height: '100vh', width: '100%', justifyContent: 'center', alignItems:'center'}}>
    <StyledCard>
      <Outlet />
    </StyledCard>
  </Box>;
};

export default MainPage;
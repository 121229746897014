import React from 'react';
import './App.css';
import DefaultTheme from './themes/DefaultTheme';
import { ThemeProvider } from '@mui/material/styles';
import Root from './components/routes/root';
import {bsc, bscTestnet} from 'wagmi/chains';
import { Web3Modal } from '@web3modal/react';
import {configureChains, createClient, WagmiConfig} from 'wagmi';
import {EthereumClient, modalConnectors, walletConnectProvider} from '@web3modal/ethereum';

const chains = [bsc];

if (process.env.NODE_ENV === 'development')
  chains.push(bscTestnet);

const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: '0770feb7584dc4e1ecd162322f1628a7' }),
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({ appName: 'web3Modal', chains }),
  provider,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);


function App() {
  return (
    <WagmiConfig client={wagmiClient}>
      <Web3Modal
        projectId="0770feb7584dc4e1ecd162322f1628a7"
        ethereumClient={ethereumClient}
      />
      <ThemeProvider theme={DefaultTheme}>
        <Root/>
      </ThemeProvider>
    </WagmiConfig>

  );
}

export default App;

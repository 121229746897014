import React, {FC} from 'react';
import { Box } from '@mui/material';
import {useRouteError} from 'react-router-dom';

const ErrorPage: FC = ()=>{
  const error:any = useRouteError();
  
  return <Box sx={{ color: '#ffffff', display: 'flex', flexDirection: 'column', background: 'linear-gradient(45deg, #aafff7 0%, #6e19c2 100%)', height: '100vh', width: '100%', justifyContent: 'center', alignItems:'center'}}>
    <p style={{fontSize: '90px', fontWeight: 'bolder', margin: '0', textAlign: 'center'}}>404</p>
    <p style={{margin: '0', textAlign: 'center'}}>{error.statusText || error.message}</p>
  </Box>;
};

export default ErrorPage;